import Home from './components/Home'
import './App.scss'
const App = () => {
  return (
    <div className="app">
     <Home />
     
    </div>
  )
}

export default App