export const Portfolio = [
  {
    id: 1,
    title: 'PC Hardware',
    img:
        'images/cssmagic.png',
    url: '#',
     },
  {
    id: 2,
    title: 'Web Application',
    img:
        'images/portfolio.png',
    url: '#',
  },
  {
    id: 3,
    title: 'AV/CCTV',
    img:
        'images/natours.png',
    url: '#',
   },
   {
    id: 4,
    title: 'Scientific Items',
    img:
        'images/natours.png',
    url: '#',
   },
   {
    id: 5,
    title: 'Stationary',
    img:
        'images/natours.png',
    url: '#',
   },
   {
    id: 6,
    title: 'Catridges',
    img:
        'images/natours.png',
    url: '#',
   },
   {
    id: 7,
    title: 'Furnitiure',
    img:
        'images/natours.png',
    url: '#',
   },
   {
    id: 8,
    title: 'General Order Supply',
    img:
        'images/natours.png',
    url: '#',
   },
];

